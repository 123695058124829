import React from "react";

function NotFoundPage() {
  return (
    <div style={{ textAlign: "center" }}>
      404 Not Found!
    </div>
  );
}

export default NotFoundPage;
